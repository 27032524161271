<template>
  <router-view />
</template>

<script>
import { mapGetters } from 'vuex'
import equal from 'fast-deep-equal'

export default {
  name: 'KuisionerContainer',
  computed: {
    ...mapGetters({
      axios: 'axios',
      isCompletedQuisionary: 'clients/isCompletedQuisionary',
    }),
    programId() {
      return this.$route.params.programId
    },
  },
  watch: {
    programId: {
      immediate: true,
      async handler(programId, old) {
        if (equal(programId, old)) return
        // await this.$store
        //   .dispatch("kuisioner/getV2Client", programId)
        // await this.$store.dispatch("kuisioner/getV2ClientDraft", programId)
      },
    },
    $route: {
      immediate: true,
      handler(val) {
        if (val.name != 'client.quizionary.done' && this.isCompletedQuisionary) {
          this.$toast({
            title: 'Error',
            description: 'Anda sudah mengisi formulir gizi',
            status: 'error',
          })
          this.$router.push('/')
        }
      }, 
    },
  },
}
</script>
